import React from "react"
import { Link } from "gatsby"
import { RiArrowLeftSLine, RiCheckboxCircleLine } from "react-icons/ri"
import { css } from "@emotion/core"

import SEO from "../components/seo"
import Layout from "../components/layout"

const Survey = ({location}) => {
  return (
    <Layout>
      <SEO />
      <div
        css={css`
          padding: 80px 0;
          align-items: center;
          height: 2600px;
        `}
      >
        <div
          className="embed-responsive embed-responsive-4by3"
          style={{ height: "100%" }}
        >
          <iframe
            className="embed-responsive-item"
            src={"https://www2.sakura-home.com.hk/survey/" + location.search}
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </Layout>
  )
}

export default Survey
